import {useQuery} from '@tanstack/react-query';
import {QUERY_KEYS} from 'spekit-datalayer';
import {IFileOrFolder, IActionOutput, IFilePickerParams} from 'spekit-types';
import {useIntegrationApp, IntegrationAppClient} from '@integration-app/react';
import {getFilesOnly, getParams} from '../helpers';

const performSearch = async (filters: IFilePickerParams, iApp: IntegrationAppClient) => {
  const {drive, store, parentId, searchTerm} = filters;
  if (!drive) return;

  let cursor = '';
  let records: IFileOrFolder[] = [];
  do {
    const params = getParams(store, drive, parentId, searchTerm);
    if (cursor) params['cursor'] = cursor;

    const response = await iApp
      .connection(store)
      .action('search-files')
      .run({...params, query: searchTerm});

    const output = response.output as IActionOutput<IFileOrFolder>;
    let updated = output.records;
    updated = getFilesOnly(updated);

    records.push(...updated);
    cursor = output.cursor;
  } while (cursor);
  return records;
};

export const useSharePointSearch = (params: IFilePickerParams) => {
  const iApp = useIntegrationApp();

  const query = useQuery({
    queryKey: [QUERY_KEYS.SharePointSearch, params],
    queryFn: () => performSearch(params, iApp),
    refetchOnWindowFocus: false,
    enabled: !!params.searchTerm && params.store === 'microsoft-sharepoint',
    staleTime: 5 * 60 * 1000,
  });

  return query;
};
