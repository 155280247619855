import {lazy, Suspense} from 'react';
import {useSelector} from 'react-redux';
import {Route, Switch, Redirect} from 'react-router-dom';
import {IFlagState, ISession} from 'spekit-types';
import DashboardSelector from './dashboardSelector';
interface IState {
  flag: IFlagState;
  layout: {
    me: ISession;
  };
}

const DASHBOARD_SERIALS = {
  MY_OVERVIEW: {
    DEFAULT: 'P8xKjEnYoZ',
    SPEK_LINK: 'd2AQeMLAb5',
  },
  GETTING_STARTED: 'zk1NZ4B1gX',
};

const Dashboard = lazy(() => import('./features/Analytics/pages/Dashboard/Dashboard'));

const HomeRoutes = () => {
  const flags = useSelector((state: IState) => state.flag);

  return (
    <Suspense fallback={<div />}>
      <Switch>
        <Route path='/app/dashboard/getting-started'>
          <Dashboard
            title='Getting Started'
            dashboardSerial={DASHBOARD_SERIALS.GETTING_STARTED}
          />
        </Route>
        <Route path='/app/dashboard/my-overview'>
          <Dashboard
            title='My overview'
            dashboardSerial={
              flags.hasSpekLinkGenerationFlag
                ? DASHBOARD_SERIALS.MY_OVERVIEW.SPEK_LINK
                : DASHBOARD_SERIALS.MY_OVERVIEW.DEFAULT
            }
          />
        </Route>
        <Route path='*'>
          {flags.hasHomePageDashboardsFlag ? (
            <Redirect to='/app/dashboard/my-overview' />
          ) : (
            <Route component={DashboardSelector} />
          )}
        </Route>
      </Switch>
    </Suspense>
  );
};

export default HomeRoutes;
