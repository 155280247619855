import {IDrive, IFileOrFolder, IFilter, IStoreParams} from 'spekit-types';
import {sub, startOfDay} from 'spekit-datalayer';
import {fileMimeTypes, mimeTypeGroups, MY_DRIVE, TIntegrationKeys} from './constants';
import {v4 as uuidv4} from 'uuid';

const parseId = (id: string) => {
  try {
    return JSON.parse(id);
  } catch (e) {
    return null;
  }
};

const sharePointMimeTypes = [
  fileMimeTypes.pdf,
  fileMimeTypes.default.doc,
  fileMimeTypes.default.sheet,
  fileMimeTypes.default.slide,
];

const getDateModifiedClause = (period: string) => {
  const today = new Date();

  switch (period) {
    case 'today': {
      const startOfToday = startOfDay(today);
      return `modifiedDate >= '${startOfToday.toISOString()}'`;
    }
    case 'yesterday': {
      const startOfYesterday = startOfDay(sub(today, {days: 1}));
      const endOfYesterday = startOfDay(today);
      return `modifiedDate >= '${startOfYesterday.toISOString()}' and modifiedDate <= '${endOfYesterday.toISOString()}'`;
    }
    case 'last7days': {
      const last7Days = startOfDay(sub(today, {days: 7}));
      return `modifiedDate >= '${last7Days.toISOString()}'`;
    }
    case 'last30days': {
      const last30Days = startOfDay(sub(today, {days: 30}));
      return `modifiedDate >= '${last30Days.toISOString()}'`;
    }
    case 'last90days': {
      const last90Days = startOfDay(sub(today, {days: 90}));
      return `modifiedDate >= '${last90Days.toISOString()}'`;
    }
    default:
      return '';
  }
};

const buildQuery = (parentId: string, searchTerm: string, filters?: IFilter) => {
  const clauses = [];

  let mimeTypesList = [
    fileMimeTypes.pdf,
    // arrays of mimetypes
    ...mimeTypeGroups.slides,
    ...mimeTypeGroups.docs,
    ...mimeTypeGroups.sheets,
  ];

  // Only include folders if we're not searching
  if (!searchTerm) {
    mimeTypesList.unshift(fileMimeTypes.folder);
  }

  if (filters) {
    const {period, type} = filters;

    if (period && period.value) {
      const dateClause = getDateModifiedClause(period.value);
      if (dateClause) {
        clauses.push(dateClause);
      }
    }

    if (type && type.value !== 'all') {
      switch (type.value) {
        case 'presentations':
          mimeTypesList = [...mimeTypeGroups.slides];
          break;
        case 'spreadsheets':
          mimeTypesList = [...mimeTypeGroups.sheets];
          break;
        case 'documents':
          mimeTypesList = [...mimeTypeGroups.docs];
          break;
        case 'pdfs':
          mimeTypesList = [fileMimeTypes.pdf];
          break;
        default:
          break;
      }
    }
  }

  const mimeTypes = mimeTypesList
    .map((mimeType) => `mimeType='${mimeType}'`)
    .join(' or ');

  clauses.push(`(${mimeTypes})`);

  if (searchTerm) {
    clauses.push(`title contains '${searchTerm}'`);
  } else {
    clauses.push(`'${parentId}' in parents`);
  }

  clauses.push('trashed = false');

  return clauses.join(' and ').trim();
};

export const getParentId = (folderId?: string, driveId?: string) => {
  if (folderId) return folderId;
  if (driveId === MY_DRIVE.id) return MY_DRIVE.id;
  return null;
};

export const getParams = (
  store: TIntegrationKeys,
  drive: IDrive,
  parentId: string | null,
  searchTerm: string = '',
  filters?: IFilter
): IStoreParams => {
  if (store !== 'gdrive') {
    return {
      siteId: 'root',
      driveId: drive.id,
      folderId: parentId ? parseId(parentId)?.fileId || 'root' : 'root',
      recursive: false,
    };
  }

  const isMyDrive = drive.id === MY_DRIVE.id;
  const effectiveParentId = isMyDrive
    ? parentId !== MY_DRIVE.id
      ? parentId!
      : 'root'
    : parentId || drive.id;

  return {
    q: buildQuery(effectiveParentId, searchTerm, filters),
    ...(isMyDrive ? {} : {corpora: 'drive', driveId: drive.id}),
  };
};

export const isFolder = (store: TIntegrationKeys, fields: IFileOrFolder['fields']) => {
  if (store === 'gdrive') {
    return fields.mimeType === fileMimeTypes.folder;
  } else if (store === 'microsoft-sharepoint') {
    return fields.itemType === 'folder';
  }
  return false;
};

export const getFilesOnly = (records: IFileOrFolder[]) => {
  return records.filter((item) => sharePointMimeTypes.includes(item.fields.mimeType));
};

export const getFilesAndFolders = (records: IFileOrFolder[], store: TIntegrationKeys) => {
  return records.filter(
    (item) =>
      sharePointMimeTypes.includes(item.fields.mimeType) || isFolder(store, item.fields)
  );
};

export const connectionEventTracker = (
  connectionKey: string,
  eventScreen: string,
  track: (eventName: string, data?: Record<string, any>) => void
) => {
  const eventName = 'Content Store Connection';
  const eventId = uuidv4();
  const trackingData = {
    connectionKey,
    eventId,
    eventStatus: 'init',
    eventError: '',
    eventDescription: '',
    eventScreen,
  };

  const trackEvent = (status: string, error: string, description: string) => {
    const d = {
      ...trackingData,
      eventStatus: status,
      eventError: error,
      eventDescription: description,
    };
    track(eventName, d);
  };
  return trackEvent;
};
